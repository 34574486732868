<template>
  <div class="grid grid-2">
    <div class="card shadow w100">
      <h3 class="title">{{ $t("message.my_profile") }}</h3>
      <br />
      <div v-if="state.isErrors" class="errors">
        <label class="error-text" v-for="(err, k) in state.errorSet" :key="k">
          {{ err }}
        </label>
      </div>
      <form
        :class="state.isErrors ? 'form-has-errors' : ''"
        @submit.prevent="updateProfile"
      >
        <!-- {{ form }} -->
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.first_name')"
              v-model="form.first_name"
            />
            <label>{{ $t("message.first_name") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.last_name')"
              v-model="form.last_name"
            />
            <label>{{ $t("message.last_name") }}</label>
          </div>
        </div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group file">
            <div class="text-center" v-if="form.profile_picture">
              <img :src="form.profile_picture" class="img-profile" />
            </div>
            <input
              type="file"
              class="form-input"
              id="upload_profile_pic"
              @input="uploadProfilePicture"
            />
            <label for="upload_profile_pic">{{
              $t("message.upload_profile_picture")
            }}</label>
          </div>
          <div class="form-group file">
            <div class="text-center" v-if="form.signature">
              <img :src="form.signature" class="img-sign" />
            </div>
            <input
              type="file"
              class="form-input"
              id="upload_signature"
              @input="uploadSignature"
            />
            <label for="upload_signature">{{
              $t("message.upload_signature")
            }}</label>
          </div>
        </div>
        <br />
        <button v-if="!form.loading" class="form-btn">
          {{ $t("message.update_profile") }}
        </button>
        <button class="form-btn disabled" disabled v-if="form.loading">
          Updaing...
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  setup() {
    const state = reactive({
      isErrors: false,
    });
    const store = useStore();
    const form = ref({
      loading: false,
    });
    const formData = new FormData();
    const uploadProfilePicture = (event) => {
      let file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (evt) => {
        form.value.profile_picture = evt.target.result;
      };
      reader.readAsDataURL(file);
      formData.append("profile_picture", file);
    };
    const uploadSignature = (event) => {
      let file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (evt) => {
        form.value.signature = evt.target.result;
      };
      reader.readAsDataURL(file);
      formData.append("signature", file);
    };
    const updateProfile = () => {
      formData.append("first_name", form.value.first_name);
      formData.append("last_name", form.value.last_name);
      form.value.loading = true;
      state.isErrors = false;
      store.dispatch("account/updateProfile", formData).then((res) => {
        form.value.loading = false;
        if (res.status === "success") {
          store.commit("notification/SEND_NOTIFICATION", res);
        } else {
          state.isErrors = true;
          state.errorSet.push(res.message);
        }
      });
    };
    onMounted(() => {
      store.dispatch("account/fetchProfile").then((res) => {
        store.commit("component/SET_LOADER", false);
        if (res.status === "success") {
          form.value = res.data;
        } else {
          alert("Something went wrong. Try again!");
        }
      });
    });
    return {
      state,
      updateProfile,
      form,
      uploadProfilePicture,
      uploadSignature,
    };
  },
};
</script>
